ul.breadcrumbs {
    overflow: hidden;

    li {
        display: block;
        float: left;


        a {
            display: inline-block;
        }
    }

    li::before {
        content: "\f105";
        display: inline-block;
        font-family: FontAwesome;
        // line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        font-size: 1em;
        margin: 0 0.5em;
        color: rgb(180,180,180);
    }

    li:first-child i {
        font-size: 1.33em;
    }

    li:first-child::before {
        display: none;
    }
}

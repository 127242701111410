body > footer {
    background-color: rgb(242,242,242);
    color: rgb(135,135,135);
    font-size: 0.85em;
    padding: 1em 0;

    a {
        color: rgb(85,85,85);
    }


    section,
    > div > div {
        margin: 3em 0;
    }

    @include desktop() {
        > div > div {
            overflow: hidden;
            margin-left: -20px;
            margin-right: -20px;

            > section {
                margin: 1em 0;
                width: 50%;
                float: left;
                padding: 0 20px;

                &:nth-child(2n+1) {
                    clear: left;
                }

                @include min-width(55em) {
                    & {
                        width: 25%;
                    }

                    &:nth-child(2n+1) {
                        clear: none;
                    }
                }
            }
        }
    }

    section > h1,
    section.nav-sections > ul > li > a {
        font-weight: bold;
        color: rgb(85,85,85);
    }

    > div > section:last-child {
        border-top: 1px solid rgb(210,210,210);
        padding-top: 1em;
    }
}

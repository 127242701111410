@import 'shared/_mixins';
@import 'shared/_vars';

@import 'layout/_reset';
@import 'layout/_grid';


@import 'layout/_headings';
@import 'layout/_header';
@import 'layout/_breadcrumbs';
@import 'layout/_footer';

@import 'layout/_ad';

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

html, body {

}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 1.6em;
    color: rgb(50,50,50);
}

a {
    text-decoration: none;
    color: $color_highlight;
}

a:hover {
    text-decoration: underline;
}

a.iconic {
    text-decoration: none;
}

a.iconic:hover > span {
    text-decoration: underline;
}

main {
    display: block;
}

main > section,
main > article,
footer > div,
section.wide .narrow,
main > .narrow {
    padding-left: 20px;
    padding-right: 20px;
    @include narrow();
}

main > .wide {
    max-width: none;
}

body {
    .row {
        width: auto;
        max-width: none;
        min-width: 0;
        margin-left: -20px;
        margin-right: -20px;
    }
}

@import './shared/_vars';
@import './shared/_mixins';
@import './_layout';

.vouchers {

    @import './shared/_voucher-navigation';

    form {
        @include narrow();
    }

    @include desktop() {
        .text-wrapper {
            overflow: hidden;

            > div {
                float: left;
                width: 70%;
                padding-right: 10px;
            }
        }
    }


    aside {
        color: rgb(100,100,100);
        margin: 2em 0;

        ul {
            margin: 0 -20px;
            background-color: rgb(248,248,248);
            padding: 0.5em 1em;
        }

        @include desktop() {
            margin: 0;
            float: right;
            width: 30%;
            padding-left: 10px;

            ul {
                margin: 0;
            }
        }

        li {
            margin: 1em 0;
            position: relative;
            padding-left: 1.5em;

            &::before {
                content: "\f00c";
                display: block;
                font-family: FontAwesome;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                position: absolute;
                left: 0;
                top: 50%;
                margin-top: -0.6em;
                line-height: 1em;
            }
        }
    }

    button {
        @include button();
        margin-top: 2em;
    }

    p + p {
        margin-top: 1em;
    }

    .list {
        overflow: hidden;
        text-align: center;

        > section {
            margin: 2em 0;
        }

        @include desktop() {
            margin: 0 -20px;
            margin-top: 2em;

            > section {
                padding: 0 20px;
                float: left;
                text-align: center;
            }

            &[data-columns="1"] > section {
                float: none;
                max-width: 50%;
                margin-left: auto;
                margin-right: auto;
            }

            &[data-columns="2"] > section {
                width: 50%;
            }

            &[data-columns="3"] > section {
                width: 33.33%;
            }

            &[data-columns="4"] > section {
                width: 25%;
            }
        }

        h1 {
            border-bottom: 3px solid rgb(220,220,220);
            padding-bottom: 0.5em;
            margin-bottom: 0.5em;
            color: $color-heading;
        }

        table {
            text-align: left;
            width: 100%;
            line-height: 1.4em;
            margin-bottom: 1em;

            td {
                padding: 0.5em 0;
            }

            td:first-child {
                color: rgb(100,100,100);

                + td {
                    font-weight: bold;
                    text-align: right;
                }
            }

            tr:last-child {
                color: green;
            }
        }
    }

    footer {
        margin-top: 4em;
        text-align: center;
        color: rgb(100,100,100);
        font-size: 0.9em;
    }

}

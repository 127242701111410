@import './_vars';
@import './_button';

@mixin mobile() {
    @media (max-width: $breakPointMax) {
        @content;
    }
}

@mixin desktop() {
    @media (min-width: $breakPointMin) {
        @content;
    }
}

@mixin min-width($width) {
    @media (min-width: $width) {
        @content;
    }
}

@mixin max-width($width) {
    @media (max-width: $width) {
        @content;
    }
}

@mixin narrow() {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

// @mixin whiteLinks() {
//     a:not(.button) {
//         color: #fff;
//         text-decoration: underline;

//         &:hover {
//             text-decoration: none;
//         }
//     }
// }

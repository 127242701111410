main > section,
main > article {

    h1, h2, h3, h4, h5, h6 {
        font-weight: bold;
        font-size: 1em;
    }

    header {
        h1 {
            font-size: 1.5em;
            font-weight: bold;
            line-height: 1.2em;

            + p {
                font-size: 1em;
                // line-height: 1.4em;
            }
        }
    }

    p + h1 {
        margin-top: 2em;
    }

    h1 {
        margin-bottom: 1em;
    }
}


.structurized {
    margin-top: 3em;
    margin-bottom: 3em;

    > header {
        margin-bottom: 3em;
    }

    section {
        p {
            margin: 1em 0;
        }

        + section {
            margin-top: 1em;
        }
    }

    @include desktop() {
        > div {
            display: flex;
            min-height: 100%;
            flex: 1;

            > div {
                width: 100%;
                flex: 1;
            }

            > aside {
                padding: 0 20px;
                // flex: 2;
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: 25em
            }
        }
    }
}

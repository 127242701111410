@import './_vars';

@mixin button($on-dark-background: false) {

    & {
        appearance: none;
        outline: none;
        box-shadow: none;
        border-radius: 0;
        border: none;


        cursor: pointer;
        padding: 1em 1.25em;

        display: inline-block;
        background-color: rgb(245,245,245);
        background-color: $color-primary;
        // border: 1px solid darken($color-primary, 40);
        border-radius: 2px;
        color: #fff;
        font-size: 0.9em;
        font-size: 1em;
        line-height: 1.2em;
        font-weight: bold;

        text-transform: none;
        text-align: center;


        box-shadow: 0 0 3px 0 rgba(0,0,0,0.5);

        // @if $on-dark-background {
        //     background-color: #fff;
        //     box-shadow: 0 0 5px 0 rgba(0,0,0,0.5);
        //     border: none;
        //     border-radius: 0;
        // }
    }

    &:hover {
        text-decoration: none;
    }

    &:active {
        background-color: darken($color-primary, 10);
    }
}

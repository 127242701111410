@import './shared/_vars';
@import './shared/_mixins';

nav.vouchers {
    text-align: center;
    line-height: 1.4em;
    background-color: rgb(248,248,248);
    padding: 10px 0;
    margin: 0 -20px;
    margin-bottom: 2em;

    > div {
        @include narrow();
    }

    ul {
        overflow: hidden;
        margin: 0 10px;

        @include desktop() {
            margin: 0 -10px;
        }
    }

    li {
        width: 50%;
        float: left;
        padding: 10px 10px;

        @include desktop() {
            width: 25%;
        }

        @include mobile() {
            &:nth-child(2n+1) {
                clear: left;
            }
        }

        &.active {
            a {
                background-color: $color-highlight;
            }

            color: #fff;
        }
    }

    a {
        color: inherit;
        background-color: rgb(240,240,240);
        border-radius: 2px;
        padding: 0.5em 1em;
        text-decoration: none;

        &, & > span {
            display: block;
        }
    }

    .discount {
        color: #fff;
        display: inline-block;
        padding: 0.25em 1em;
        background-color: $color-primary;
        border-radius: 2px;
        font-size: 0.8em;
        line-height: 1.4em;
        margin-bottom: 1em;
    }

    .title {
        font-weight: bold;
        font-size: 1.1em;
        margin: 0.5em 0;
    }

    .savings {
        span {
            font-weight: bold;
        }
    }
}

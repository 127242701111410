@import './../shared/_vars';

body > header {
    border-top: 5px solid $color_heading;
    position: relative;
    padding: 0 20px;
    border-bottom: 1px solid rgb(220,220,220);

    a, a:hover {
        color: inherit;
        text-decoration: none;
    }

    > a {
        font-family: 'Dancing Script', sans-serif;
        font-weight: bold;
        font-size: 2em;
        color: $color_primary;
        display: inline-block;
        padding: 0.5em 0;

        @include desktop() {
            position: absolute;
            left: 20px;
            top: 0.25em;
            text-align: right;
        }
    }



    ul {
        text-align: center;
        margin-top: 0.5em;

        @include desktop() {
            text-align: right;
            margin-top: 0;
            padding: 1em 0;
        }
    }

    li {
        display: inline-block;
        margin-left: 1em;
        font-weight: bold;

        @include mobile() {
            font-size: 0.9em;
            text-transform: uppercase;
            margin-left: 0;
        }

        a {
            display: block;
            padding: 0.5em 1.5em;

            @include desktop() {
                padding: 0.5em 1em;
                // padding-top: 1em;
                // padding-bottom: 1em;
            }
        }

        &.active {
            position: relative;
            color: $color_highlight;

            &::after {
                position: absolute;
                content: '';
                display: block;
                bottom: -1px;
                left: 0;
                right: 0;
                height: 3px;
                background-color: $color_highlight;
            }
        }
    }


    li:first-child {
        @include mobile() {
            position: absolute;
            right: 20px;
            top: 1.25em;
        }

        line-height: 1.4em;
        font-size: 0.8em;
        background-color: rgb(240,240,240);
        color: rgb(100,100,100);
        border-radius: 2px;
        padding: 0.3em 0.5em;
        font-weight: normal;
        text-transform: none;

        &::before {
            content: 'Stand: ';
        }

        @include desktop() {
            margin-right: 2em;

            &::before {
                content: 'Letzte Aktualisierung: ';
            }
        }
    }

}

@import './../shared/_mixins';

.netcup-promo {
    margin: 2em auto;
    display: block;
    width: 200px;
    height: 200px;
    background-repeat: no-repeat;
    background-image: url(/img/nc-mob.png);

    @include min-width(508px) {
        width: 468px;
        height: 60px;
        background-image: url(/img/nc-reg.png);
    }

    @include min-width(768px) {
        width: 728px;
        height: 90px;
        background-image: url(/img/nc-reg-wide.png);
    }
}

.ref-img {
    visibility: hidden;
}


.row {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;

}
.row .row {
    width: auto;
    max-width: none;
    min-width: 0;
    margin-left: -20px;
    margin-right: -20px;
}


.col, .cols {
    min-height: 1px;
    padding: 0 20px;
    position: relative;
    margin-bottom: 2em;
}

.col, .cols {
    box-sizing: border-box;
}

.row:before,
.row:after,
.cf:before,
.cf:after {
    content:" ";
    display:table;
}

.row:after,
.cf:after {
    clear: both;
}

.row,
.cf {
    zoom: 1;
}



@media (min-width: 768px) {


    .col, .cols {
        float: left;
        margin-bottom: 0;
    }

    [class*="col"] + [class*="col"]:last-child {
        float: right;
    }

    [class*="col"] + [class*="col"].end {
        float: left;
    }

    .row .one {
        width: 8.33%;
    }

    .row .two {
        width: 16.66%;
    }

    .row .three {
        width: 25%;
    }

    .row .four {
        width: 33.33%;
    }

    .row .five {
        width: 41.66%;
    }

    .row .six {
        width: 50%;
    }

    .row .seven {
        width: 58.33%;
    }

    .row .eight {
        width: 66.66%;
    }

    .row .nine {
        width: 75%;
    }

    .row .ten {
        width: 83.33%;
    }

    .row .eleven {
        width: 91.66%;
    }

    .row .twelve {
        width: 100%;
    }

    .row {
        margin-bottom: 2em;
    }
}
